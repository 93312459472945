import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16.113 16.75c-3.171 0-5.75-3.028-5.75-6.75s2.579-6.75 5.75-6.75c3.17 0 5.75 3.028 5.75 6.75s-2.58 6.75-5.75 6.75m5.379-1.238c1.159-1.463 1.871-3.392 1.871-5.512 0-4.549-3.252-8.25-7.25-8.25S8.863 5.451 8.863 10c0 2.1.698 4.013 1.838 5.471-5.055 1.039-6.451 5.634-6.451 8.112V29h1.5v-5.417c0-.258.08-6.259 6.259-6.789 1.168.917 2.581 1.456 4.104 1.456 1.512 0 2.917-.531 4.08-1.437 5.986.638 6.066 6.517 6.066 6.769l-.009 5.417 1.5.002.009-5.418c0-2.431-1.358-6.958-6.267-8.071" /></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;