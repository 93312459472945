import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 27c6.075 0 11-4.925 11-11S22.075 5 16 5 5 9.925 5 16s4.925 11 11 11m0 1.5C9.096 28.5 3.5 22.904 3.5 16S9.096 3.5 16 3.5 28.5 9.096 28.5 16 22.904 28.5 16 28.5" /><path d="M16 13.5a1 1 0 0 1 1 1V22a1 1 0 0 1-2 0v-7.5a1 1 0 0 1 1-1m0-1.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2" /></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;