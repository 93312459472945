import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgBurgerText32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M30.5 30h-29a.75.75 0 0 0 0 1.5h29a.75.75 0 0 0 0-1.5m0-7h-29a.75.75 0 0 0 0 1.5h29a.75.75 0 0 0 0-1.5m0-7h-29a.75.75 0 0 0 0 1.5h29a.75.75 0 0 0 0-1.5M4.028 11l-2.07-5.962h-.039c.055.885.083 1.716.083 2.49V11H.942V3.861h1.645L4.569 9.54h.029l2.041-5.679h1.65V11H7.166V7.47c0-.355.009-.817.027-1.387s.033-.915.046-1.035H7.2L5.056 11h-1.03zm10.781 0h-4.038V3.861h4.038v.986h-2.871V6.79h2.69v.977h-2.69v2.241h2.871v.991zm8.09 0h-1.431l-3.511-5.688h-.039l.024.317c.046.605.068 1.159.068 1.66V11h-1.06V3.861h1.416l3.501 5.659h.029c-.006-.075-.02-.348-.039-.818s-.029-.837-.029-1.101v-3.74h1.069V11zm8.081-7.139V8.48c0 .527-.113.989-.339 1.384s-.553.7-.981.913c-.428.213-.94.32-1.536.32-.885 0-1.574-.234-2.065-.703s-.737-1.113-.737-1.934v-4.6h1.172v4.517c0 .589.137 1.024.41 1.304s.693.42 1.26.42c1.1 0 1.65-.578 1.65-1.733V3.861h1.167zm-4.366-1.342c0-.202.055-.348.164-.437s.242-.134.398-.134c.173 0 .31.05.413.151s.154.241.154.42c0 .173-.052.311-.156.415s-.241.156-.41.156c-.156 0-.289-.047-.398-.142s-.164-.238-.164-.43zm1.919 0c0-.202.055-.348.164-.437s.242-.134.398-.134c.173 0 .311.05.415.151s.156.241.156.42c0 .176-.054.315-.161.417s-.244.154-.41.154c-.156 0-.289-.047-.398-.142s-.164-.238-.164-.43z" /></SvgIcon>;
};
const Memo = memo(SvgBurgerText32);
export default Memo;