import deepmerge from '@mui/utils/deepmerge';
import type { Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { DEFAULTCOLOR_TEXT_DISABLED } from '../default/palette';
import type { Device } from '../default';
import { generateDefaultThemeOptions } from '../default';
import { fonts } from './fonts';

const COLOR_PRIMARY_MAIN = '#a12d6a';
const DEFAULTCOLOR_TEXT_DARK = '#333333';
const DEFAULTCOLOR_TEXT_LIGHT = '#ffffff';
const DEFAULTCOLOR_TEXT_TRANSPARENT_DARK = 'rgba(51, 51, 51, 0.65)';
const DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT = 'rgba(255, 255, 255, 0.65)';
const DEFAULTCOLOR_COMMON_LIGHT = '#e9ebec';

/** Define theme object.
 * See here for more info: https://mui.com/customization/theming/ */
const themeOptions: ThemeOptions = {
  // https://mui.com/customization/palette/
  palette: {
    primary: {
      main: COLOR_PRIMARY_MAIN,
      textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
    },
    secondary: {
      main: '#27b9ea',
    },
    grey: {
      main: '#c7cccf',
      light: DEFAULTCOLOR_COMMON_LIGHT,
      dark: '#545454',
    },
    text: {
      disabled: DEFAULTCOLOR_TEXT_DISABLED,
      primary: DEFAULTCOLOR_TEXT_DARK,
      secondary: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      dark: DEFAULTCOLOR_TEXT_DARK,
      darkTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      light: DEFAULTCOLOR_TEXT_LIGHT,
      lightTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
    },
    footer: {
      main: DEFAULTCOLOR_COMMON_LIGHT,
      contrastText: DEFAULTCOLOR_TEXT_DARK,
    },
  },
  typography: {
    fontFamily: fonts.primary.style.fontFamily,
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: '',
    },
    MuiButton: {
      styleOverrides: {
        root: ({
          theme: {
            unstable_sx: sx,
            palette: { grey },
          },
        }) =>
          sx({
            ':disabled': { bgcolor: grey.main, color: grey.dark },
          }),
      },
    },
  },
};

/** Merge with default theme options and create a theme instance
 * See here for more info: https://mui.com/customization/theming/#api */
const themes: { [type in Device]: Theme | undefined } = {
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
};

const theme = (device: Device = 'desktop') => {
  if (!themes[device]) {
    themes[device] = createTheme(deepmerge(generateDefaultThemeOptions(device), themeOptions));
  }

  return themes[device];
};

// eslint-disable-next-line import/no-default-export
export default theme;
