import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgNewsletter32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M26.5 26c0 .361-.22.601-.421.601H6.921c-.2 0-.421-.242-.421-.601V12.95l8.903 5.639c.587.543 1.566.552 2.164 0l8.933-5.642zM8 11.361l8.5-6.807 9.443 7.563-8.91 5.628-.144.11c-.211.194-.599.193-.807.001l-.144-.11-8.883-5.628zm17-1.923-7.719-6.181c-.212-.171-.495-.257-.779-.257s-.567.084-.783.257L5 11.841V26c0 1.159.86 2.101 1.921 2.101h19.158c1.062 0 1.921-.94 1.921-2.101V11.841z" /><path d="M17.063 11.203c-.03.451-.096.765-.196.942-.176.309-.651.357-.877.073-.287-.363-.18-1.106.113-1.422.262-.282.643-.316 1.002-.261zM16.477 15c.77 0 1.467-.135 2.091-.406v-.981c-.36.132-.727.233-1.102.304-.907.173-2.025.218-2.732-.486-.785-.78-.745-2.23-.287-3.164.207-.421.506-.744.898-.969.86-.495 2.245-.511 2.985.218.617.608.716 1.734.429 2.521-.132.363-.559.554-.559.005l.008-.402.088-1.807a4.533 4.533 0 0 0-.746-.191c-.627-.108-1.301-.117-1.881.18a1.748 1.748 0 0 0-.749.715c-.379.679-.389 1.746.151 2.348.586.655 1.702.588 2.263-.051h.063c.387.616 1.309.647 1.837.214.24-.197.427-.47.562-.818.338-.869.265-1.953-.2-2.763a2.714 2.714 0 0 0-1.155-1.086c-1.101-.558-2.565-.509-3.64.084a3.332 3.332 0 0 0-1.322 1.323c-.731 1.309-.699 3.273.447 4.345.618.578 1.468.867 2.551.867" /></SvgIcon>;
};
const Memo = memo(SvgNewsletter32);
export default Memo;