import localFont from 'next/font/local';

const primary = localFont({
  src: [
    {
      path: './fonts/opensans-regular-webfont.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/opensans-bold-webfont.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  variable: '--font-primary',
});

export const fonts = {
  primary,
};
