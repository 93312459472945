import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSearch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M12.933 20.533a6.933 6.933 0 1 0 0-13.866 6.933 6.933 0 0 0 0 13.866m0 2.667c-5.302 0-9.6-4.298-9.6-9.6S7.631 4 12.933 4s9.6 4.298 9.6 9.6-4.298 9.6-9.6 9.6" /><path d="m17.991 20.543 8.4 8.4a1.333 1.333 0 1 0 1.886-1.886l-8.4-8.4a1.333 1.333 0 1 0-1.886 1.886" /></SvgIcon>;
};
const Memo = memo(SvgSearch32);
export default Memo;