import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAppIcon = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" sx={{
    ...sx
  }} {...other}><g fill="none"><path fill="#A12D6A" d="M0 0h1024v1024H0z" /><path fill="#FFF" d="M622.526 827.324c-24.121-.224-48.312-.173-72.221-3.426-36.177-4.924-63.788-23.084-80.67-55.91-19.662-38.23-36.074-78.013-55.457-116.38-16.815-33.285-35.914-65.088-60.034-93.791-10.222-12.164-23.487-18.857-37.734-24.458-2.708-1.063-4.1.648-5.627 2.181-8.667 8.72-13.437 19.45-16.071 31.25-2.84 12.718-3.542 25.673-3.732 38.624-.509 34.827.484 69.647 1.197 104.458.601 29.357.463 58.707.715 88.057.032 3.86-1.145 4.798-4.813 4.4-6.846-.736-13.73-1.215-20.61-1.502-2.86-.12-3.8-1.198-3.818-3.922-.134-20.307-1.611-40.556-2.92-60.807-1.751-27.113-2.43-54.27-3.718-81.393-1.21-25.506-7.568-50.288-15.403-74.57-15.768-48.88-38.114-94.613-65.92-137.84-14.578-22.662-27.138-46.576-32.69-73.226-11.212-53.81.58-101.66 39.278-141.75 15.085-15.628 33.124-27.067 53.775-34.24 2.323-.806 4.445-.873 6.843-.311 16.222 3.799 28.949 13.553 41.752 23.486 37.277 28.919 56.482 68.396 67.238 112.92 5.65 23.386 10.349 46.97 13.418 70.83 2.072 16.11-.352 32.095-2.588 48.024-2.561 18.25-5.404 36.465-4.261 55.006.207 3.364 1.05 6.308 2.796 9.242 9.862 16.57 22.025 31.51 33.297 47.08 16.528 22.826 29.016 47.758 39.28 73.904 5.996 15.278 12.51 30.336 21.608 44.135 1.636 2.484 3.657 4.055 6.38 5.178 16.944 6.979 34.762 8.16 52.666 7.063a1912.25 1912.25 0 0 0 92.66-7.944c22.04-2.43 42.324-9.79 60.668-22.316 2.327-1.587 4.72-3.077 7.12-4.554 8.763-5.39 17.635-7.173 28.172-4.455 27.747 7.147 55.629 13.953 83.732 19.504 16.119 3.182 25.047 12.802 31.196 26.587 6.517 14.608 8.432 30.292 10.735 45.89.98 6.638 2.165 13.243 3.172 19.877.407 2.679 1.334 3.964 4.51 3.753 14.837-.978 29.695-1.613 44.537-2.494 5.784-.345 5.892-.221 6.17 5.683.38 8.045-3.242 13.764-9.811 17.93-4.807 3.049-10.123 4.93-15.57 6.441-58.548 16.239-117.985 27.426-178.7 31.538-20.167 1.367-40.343 2.035-60.547 2.248" /></g></SvgIcon>;
};
const Memo = memo(SvgAppIcon);
export default Memo;