import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M10.693 7c-1.712 0-3.595.856-4.794 2.226-1.712 2.054-1.883 5.307-.342 7.876 3.938 6.164 8.903 7.704 10.273 8.047h.342c1.37-.342 6.335-1.883 10.273-8.047 1.541-2.568 1.37-5.821-.342-7.876C24.905 7.856 23.192 7 21.309 7c-1.712 0-3.253.685-4.451 1.883l-.171.171a.828.828 0 0 1-1.198 0l-.171-.171C14.12 7.685 12.407 7 10.695 7zm10.614-1.5c2.278 0 4.411 1.011 5.946 2.765 2.144 2.573 2.326 6.524.454 9.643-3.319 5.195-7.37 7.744-11.173 8.694l-.179.045h-.712l-.179-.045c-3.803-.951-7.854-3.5-11.195-8.73-1.85-3.084-1.668-7.035.5-9.635 1.471-1.682 3.76-2.738 5.923-2.738 1.982 0 3.94.742 5.392 2.047 1.452-1.322 3.267-2.047 5.223-2.047z" /></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;