import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M26.5 10.6c0-1.3-1-2.2-2.4-2.2h-4.4V7c0-2.1-1.7-3.7-3.8-3.7S12.1 4.9 12.1 7v1.4H7.9c-1.2 0-2.3 1-2.4 2.3l-1.1 14v.1c0 1.2.4 2.1 1.1 2.8.8.8 1.8 1.2 2.8 1.2h15.3c1 0 2-.4 2.8-1.2.7-.7 1.1-1.7 1.1-2.8zM13.6 7c0-1.2 1-2.2 2.3-2.2s2.3 1 2.3 2.2v1.4h-4.6zm11.8 19.5c-.5.5-1.2.8-1.8.8H8.4c-.6 0-1.3-.3-1.8-.8-.4-.4-.6-1-.6-1.7l1-14.1c0-.5.4-.9.9-.9h4.2v1.9c0 .4.3.7.7.7.4 0 .7-.3.7-.7V9.8h4.6v1.9c0 .4.3.7.7.7.4 0 .7-.3.7-.7V9.8h4.4c.5 0 .9.3.9.8l1.1 14.1c.2.8 0 1.3-.5 1.8" /></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;